/**
 *
 * @author Godwin Kimani <josephgodwinke@gmail.com>
 */

import './thanks.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import aiLogo from '../../assets/img/logo_ai_films3.0.png';
import robotMail from '../../assets/img/robot-mail.png';

export default function Thanks() {
	const { t } = useTranslation();
	return (
		<div className="thanks">
			<div className="top">
				<div className="wrapper">
					<a href="/">
						<img className="logo" src={aiLogo} alt="" />
					</a>
				</div>
			</div>
			<div className="container">
				<h1>{t('Thanks!')}</h1>
				<p className="aifilms">{t('Look in your mailbox')}</p>
				<h2>{t('You will get an email. Please check your INBOX or SPAM  folder.')}</h2>
				<img className="img-robot" src={robotMail} alt="" />
				<p className="remember">
					{t("Remember to whitelist AI FILMS so you don't miss our answer!")}
				</p>
			</div>
		</div>
	);
}
