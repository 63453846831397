/* eslint-disable no-unused-vars */

/**
 *
 * @author Godwin Kimani <josephgodwinke@gmail.com>
 */

import './candidatesSubmit.scss';

import emailjs from '@emailjs/browser';
import axios from 'axios';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';

import aiLogo from '../../assets/img/logo_ai_films3.0.png';

export default function Submission() {
	const { t, i18n } = useTranslation();
	const [captcha, setCaptcha] = React.useState(null);
	const [captchaError, setCaptchaError] = React.useState(false);
	const [country, setCountry] = React.useState('US');
	const [loading, setLoading] = React.useState(false);

	const navigate = useNavigate();

	const countryOptions = [
		{ value: 'US', label: 'United States' },
		{ value: 'AL', label: 'Albania' },
		{ value: 'DZ', label: 'Algeria' },
		{ value: 'AO', label: 'Angola' },
		{ value: 'AG', label: 'Antigua & Barbuda' },
		{ value: 'AR', label: 'Argentina' },
		{ value: 'AM', label: 'Armenia' },
		{ value: 'AU', label: 'Australia' },
		{ value: 'AT', label: 'Austria' },
		{ value: 'AZ', label: 'Azerbaijan' },
		{ value: 'BS', label: 'Bahamas' },
		{ value: 'BH', label: 'Bahrain' },
		{ value: 'BD', label: 'Bangladesh' },
		{ value: 'BE', label: 'Belgium' },
		{ value: 'BJ', label: 'Benin' },
		{ value: 'BT', label: 'Bhutan' },
		{ value: 'BO', label: 'Bolivia' },
		{ value: 'BA', label: 'Bosnia & Herzegovina' },
		{ value: 'BW', label: 'Botswana' },
		{ value: 'BN', label: 'Brunei' },
		{ value: 'BG', label: 'Bulgaria' },
		{ value: 'KH', label: 'Cambodia' },
		{ value: 'CA', label: 'Canada' },
		{ value: 'CL', label: 'Chile' },
		{ value: 'CO', label: 'Colombia' },
		{ value: 'CR', label: 'Costa Rica' },
		{ value: 'CI', label: 'Côte d’Ivoire' },
		{ value: 'HR', label: 'Croatia' },
		{ value: 'CY', label: 'Cyprus' },
		{ value: 'CZ', label: 'Czech Republic' },
		{ value: 'DK', label: 'Denmark' },
		{ value: 'DO', label: 'Dominican Republic' },
		{ value: 'EC', label: 'Ecuador' },
		{ value: 'EG', label: 'Egypt' },
		{ value: 'SV', label: 'El Salvador' },
		{ value: 'EE', label: 'Estonia' },
		{ value: 'ET', label: 'Ethiopia' },
		{ value: 'FI', label: 'Finland' },
		{ value: 'FR', label: 'France' },
		{ value: 'GA', label: 'Gabon' },
		{ value: 'GM', label: 'Gambia' },
		{ value: 'DE', label: 'Germany' },
		{ value: 'GH', label: 'Ghana' },
		{ value: 'GR', label: 'Greece' },
		{ value: 'GT', label: 'Guatemala' },
		{ value: 'GY', label: 'Guyana' },
		{ value: 'HK', label: 'Hong Kong' },
		{ value: 'HU', label: 'Hungary' },
		{ value: 'IS', label: 'Iceland' },
		{ value: 'IN', label: 'India' },
		{ value: 'ID', label: 'Indonesia' },
		{ value: 'IE', label: 'Ireland' },
		{ value: 'IL', label: 'Israel' },
		{ value: 'IT', label: 'Italy' },
		{ value: 'JM', label: 'Jamaica' },
		{ value: 'JP', label: 'Japan' },
		{ value: 'JO', label: 'Jordan' },
		{ value: 'KZ', label: 'Kazakhstan' },
		{ value: 'KE', label: 'Kenya' },
		{ value: 'KW', label: 'Kuwait' },
		{ value: 'LA', label: 'Laos' },
		{ value: 'LV', label: 'Latvia' },
		{ value: 'LI', label: 'Liechtenstein' },
		{ value: 'LT', label: 'Lithuania' },
		{ value: 'LU', label: 'Luxembourg' },
		{ value: 'MO', label: 'Macao SAR China' },
		{ value: 'MG', label: 'Madagascar' },
		{ value: 'MY', label: 'Malaysia' },
		{ value: 'MT', label: 'Malta' },
		{ value: 'MU', label: 'Mauritius' },
		{ value: 'MX', label: 'Mexico' },
		{ value: 'MD', label: 'Moldova' },
		{ value: 'MC', label: 'Monaco' },
		{ value: 'MN', label: 'Mongolia' },
		{ value: 'MA', label: 'Morocco' },
		{ value: 'MZ', label: 'Mozambique' },
		{ value: 'NA', label: 'Namibia' },
		{ value: 'NL', label: 'Netherlands' },
		{ value: 'NG', label: 'Nigeria' },
		{ value: 'NZ', label: 'New Zealand' },
		{ value: 'MK', label: 'North Macedonia' },
		{ value: 'SJ', label: 'Norway' },
		{ value: 'OM', label: 'Oman' },
		{ value: 'PK', label: 'Pakistan' },
		{ value: 'PA', label: 'Panama' },
		{ value: 'PY', label: 'Paraguay' },
		{ value: 'PE', label: 'Peru' },
		{ value: 'PH', label: 'Philippines' },
		{ value: 'PL', label: 'Poland' },
		{ value: 'PT', label: 'Portugal' },
		{ value: 'QA', label: 'Qatar' },
		{ value: 'RO', label: 'Romania' },
		{ value: 'RW', label: 'Rwanda' },
		{ value: 'SM', label: 'San Marino' },
		{ value: 'SA', label: 'Saudi Arabia' },
		{ value: 'SN', label: 'Senegal' },
		{ value: 'RS', label: 'Serbia' },
		{ value: 'SG', label: 'Singapore' },
		{ value: 'SK', label: 'Slovakia' },
		{ value: 'SI', label: 'Slovenia' },
		{ value: 'ZA', label: 'South Africa' },
		{ value: 'KR', label: 'South Korea' },
		{ value: 'ES', label: 'Spain' },
		{ value: 'LK', label: 'Sri Lanka' },
		// { value: 'St. Lucia', label: 'St. Lucia' },
		{ value: 'SE', label: 'Sweden' },
		{ value: 'CH', label: 'Switzerland' },
		{ value: 'TW', label: 'Taiwan' },
		{ value: 'TZ', label: 'Tanzania' },
		{ value: 'TH', label: 'Thailand' },
		{ value: 'TT', label: 'Trinidad & Tobago' },
		{ value: 'TN', label: 'Tunisia' },
		{ value: 'TR', label: 'Turkey' },
		{ value: 'AE', label: 'United Arab Emirates' },
		{ value: 'UK', label: 'United Kingdom' },
		{ value: 'UY', label: 'Uruguay' },
		{ value: 'UZ', label: 'Uzbekistan' },
		{ value: 'VN', label: 'Viet Nam' }
	];

	const handleChange = (value) => {
		setCaptcha(value);
	};

	const handleCountryChange = (selectedOption) => {
		setCountry(selectedOption.value);
		console.log('Option selected:', selectedOption.value);
	};

	const sendEmail = async (data) => {
		await emailjs
			.send(
				process.env.REACT_APP_YOUR_SERVICE_ID,
				process.env.REACT_APP_YOUR_TEMPLATE_ID,
				data,
				process.env.REACT_APP_YOUR_PUBLIC_KEY
			)
			.then(
				(result) => {
					// console.log(result.text);
				},
				(error) => {
					// console.log(error.text);
					enqueueSnackbar(error.message, {
						variant: 'error',
						autoHideDuration: 1000,
						preventDuplicate: true
					});
				}
			);
	};

	const formik = useFormik({
		initialValues: {
			fullName: '',
			work: '',
			email: '',
			terms: false
		},
		validationSchema: Yup.object({
			fullName: Yup.string().max(256, 'Must be 256 characters or less').required('Required'),
			work: Yup.string().url('Must be a valid url').required('Required'),
			email: Yup.string().email('Invalid email address').required('Required'),
			terms: Yup.boolean().oneOf([true], 'You must accept the our terms and conditions')
		}),
		onSubmit: async (values) => {
			// alert(JSON.stringify(values, null, 2));
			setLoading(true);
			if (captcha === null) {
				setLoading(false);
				setCaptchaError(true);
			}
			try {
				await axios.post(`${process.env.REACT_APP_API_URL}candidate-submit`, {
					name: values.fullName,
					email: values.email,
					country,
					link: values.work,
					captcha
				});
				sendEmail({
					name: values.fullName,
					email: values.email,
					country,
					link: values.work
				});

				setCaptcha('');
				setLoading(false);
				navigate('/thanks');
				// console.log(res)
			} catch (err) {
				// console.log(err)
				setLoading(false);
			}
		}
	});

	return (
		<div className="submission">
			<div className="top">
				<div className="wrapper">
					<a href="/">
						<img className="logo" src={aiLogo} alt="" />
					</a>
					<a role="button" onClick={() => navigate('/terms-conditions')} className="terms">
						{t('Terms and Conditions')}
					</a>
				</div>
			</div>
			<div className="container">
				<h1>AI FILMS</h1>
				<p className="aifilms">The Future of Storytelling</p>
				<h2>{t('Open call for AI creators')}</h2>
				<form onSubmit={formik.handleSubmit} className="input">
					<label htmlFor="country">{t('Country')}</label>
					<Select
						id="country"
						name="country"
						type="text"
						className="countrySelect"
						onChange={handleCountryChange}
						onBlur={formik.handleBlur}
						defaultValue={countryOptions[0]}
						options={countryOptions}
						theme={(theme) => ({
							...theme,
							borderRadius: 0,
							colors: {
								...theme.colors,
								primary: 'black',
								primary25: '#fe020d',
								primary50: 'white',
								primary75: '#fe020d',
								neutral0: 'black',
								neutral5: 'white',
								neutral10: 'white',
								neutral20: 'white',
								neutral30: 'white',
								neutral40: 'white',
								neutral50: 'white',
								neutral60: 'white',
								neutral70: 'white',
								neutral80: 'white',
								neutral90: 'white'
							},
							borderStyle: 'solid',
							borderColor: 'white'
						})}
						autoFocus={false}
					/>

					<label htmlFor="fullName">{t('Enter your full name')}</label>
					<input
						id="fullName"
						name="fullName"
						type="text"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.fullName}
					/>
					{formik.touched.fullName && formik.errors.fullName ? (
						<span className="error">{formik.errors.fullName}</span>
					) : null}

					<label htmlFor="email">{t('Email Address')}</label>
					<input
						id="email"
						name="email"
						type="email"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.email}
					/>
					{formik.touched.email && formik.errors.email ? (
						<span className="error">{formik.errors.email}</span>
					) : null}

					<label htmlFor="work">{t('Where can we see your work?')}</label>
					<input
						id="work"
						name="work"
						type="text"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.work}
					/>
					{formik.touched.work && formik.errors.work ? (
						<span className="error">{formik.errors.work}</span>
					) : null}

					<label htmlFor="work">{t('Terms and Conditions')}</label>
					<div
						style={{
							backgroundColor: 'white',
							maxHeight: '200px',
							overflow: 'scroll',
							marginBottom: '20px',
							background: 'rgb(0, 0, 0)',
							color: 'rgb(255, 255, 255)',
							overflowY: 'scroll',
							overflowX: 'scroll',
							border: '1px solid white'
						}}
					>
						<p style={{ fontSize: '16px', padding: '15px' }}>
							Please read the following terms and conditions (&quot;Terms&quot;) carefully before
							registering as a candidate on the streaming platform (&quot;Platform&quot;). These
							Terms govern your access to and use of the Platform as a candidate, and constitute a
							legally binding agreement between you and the Platform.
						</p>
						<br />
						<ol>
							<li>
								<b>Acceptance of Terms</b> By registering as a candidate on the Platform, you
								acknowledge and agree to be bound by these Terms. If you do not agree with any of
								the provisions of these Terms, you must not register as a candidate on the Platform.
							</li>
							<br />
							<li>
								<b>Eligibility</b> To register as a candidate on the Platform, you must be at least
								18 years of age or have obtained legal consent from a parent or guardian if you are
								under the age of 18. You must also have the legal capacity to enter into a contract.
							</li>
							<br />
							<li>
								<b>Registration Information</b> To complete the registration process on the
								Platform, you must provide accurate, current, and complete information about
								yourself, including your name, email address, and a link where your work can be
								viewed (e.g., Instagram social media account).
							</li>
							<br />
							<li>
								<b>Email Communication</b> By registering as a candidate on the Platform, you
								consent to receive emails from the Platform related to your registration and any
								relevant updates. These emails may include information about your account,
								notifications, promotional offers, and other communication related to your
								candidacy. You may opt-out of receiving promotional emails at any time by following
								the unsubscribe instructions provided in the email.
							</li>
							<br />
							<li>
								<b>Use of Provided Link</b> By registering as a candidate on the Platform, you grant
								the Platform the non-exclusive, worldwide, royalty-free right to use, display, and
								promote the provided link where your work can be viewed. This includes, but is not
								limited to, featuring your work on the Platform, showcasing it to potential viewers,
								and sharing it on social media platforms or other promotional channels for the
								purpose of promoting the Platform and its candidates. You retain all ownership
								rights to your work.
							</li>
							<br />
							<li>
								<b>Prohibited Conduct</b> As a candidate on the Platform, you agree not to engage in
								any of the following activities:
							</li>
							<br />
							<ul>
								<li>Violating any applicable laws, regulations, or third-party rights.</li>
								<li>
									Impersonating any person or entity or falsely representing your affiliation with
									any person or entity.
								</li>
								<li>
									Uploading, posting, transmitting, or otherwise making available any content that
									is harmful, defamatory, obscene, or infringing upon any intellectual property
									rights.
								</li>
								<li>
									Attempting to gain unauthorised access to the Platform, its servers, or any other
									systems or networks connected to the Platform.
								</li>
								<li>
									Engaging in any activity that disrupts, interferes with, or imposes an
									unreasonable burden on the Platform&lsquo;s infrastructure or its proper
									functioning.
								</li>
							</ul>
							<br />
							<li>
								<b>Intellectual Property</b>
								The Platform respects the intellectual property rights of others and expects you to
								do the same. By registering as a candidate, you affirm that you have the necessary
								rights or permissions to use and display the content provided in the link where your
								work can be viewed.
							</li>
							<br />
							<li>
								<b>Disclaimer of Warranties</b> The Platform provides the candidate registration
								service on an &quot;as is&quot; and &quot;as available&quot; basis. To the fullest
								extent permitted by applicable law, the Platform disclaims all warranties, whether
								express or implied, including but not limited to the warranties of merchantability,
								fitness for a particular purpose, and non-infringement.
							</li>
							<br />
							<li>
								<b>Limitation of Liability</b> To the fullest extent permitted by applicable law,
								the Platform shall not be liable for any indirect, incidental, special,
								consequential, or punitive damages, including but not limited to loss of profits,
								data, or goodwill, arising from your use of the Platform or your inability to use
								the Platform.
							</li>
							<br />
							<li>
								<b> Modification of Terms</b> The Platform reserves the right to modify or amend
								these Terms at any time. Any changes to the Terms will be effective immediately upon
								posting the updated version on the Platform. It is your responsibility to review the
								Terms periodically for any updates. By continuing to use the Platform after the
								posting of changes, you accept and agree to be bound by the modified Terms.
							</li>
							<br />
							<li>
								<b>Termination</b>
								The Platform reserves the right to suspend or terminate your candidacy and access to
								the Platform at any time, without prior notice or liability, for any reason,
								including but not limited to a violation of these Terms.
							</li>
							<br />
							<li>
								<b>Governing Law and Jurisdiction</b> These Terms shall be governed by and construed
								in accordance with the laws of the jurisdiction where the Platform operates. Any
								disputes arising out of or relating to these Terms or your use of the Platform shall
								be subject to the exclusive jurisdiction of the competent courts in that
								jurisdiction.
							</li>
							<br />
							<li>
								<b>Severability</b> If any provision of these Terms is found to be unenforceable or
								invalid, that provision shall be limited or eliminated to the minimum extent
								necessary, and the remaining provisions of these Terms shall remain in full force
								and effect.
							</li>
							<br />
							<li>
								<b>Entire Agreement</b> These Terms constitute the entire agreement between you and
								the Platform regarding your registration as a candidate on the Platform, superseding
								any prior agreements or understandings, whether oral or written.
							</li>
							<br />
							<br />
						</ol>

						<p style={{ fontSize: '16px', padding: '15px' }}>
							By accepting these Terms, you acknowledge that you have read, understood, and agree to
							be bound by all of the provisions contained herein. If you do not agree with any part
							of these Terms, you should not proceed with the registration process.
						</p>
					</div>

					<label htmlFor="terms">
						{t('I accept the above terms and conditions')} &nbsp;
						<input
							id="terms"
							name="terms"
							type="checkbox"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.terms}
						/>
					</label>

					{formik.touched.terms && formik.errors.terms ? (
						<span className="error">{formik.errors.terms}</span>
					) : null}

					<ReCAPTCHA
						sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
						size="normal"
						onChange={handleChange}
					/>
					{captchaError && <span className="error">{t('Please check the captcha')}</span>}

					{loading === false ? (
						<button className="submissionButton" type="submit">
							Start
						</button>
					) : (
						<button
							className="submissionButton"
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<Oval
								ariaLabel="loading-indicator"
								height={30}
								width={30}
								strokeWidth={5}
								strokeWidthSecondary={1}
								color="white"
								secondaryColor="white"
							/>
						</button>
					)}
				</form>
			</div>
		</div>
	);
}
