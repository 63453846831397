/**
 *
 * @author Godwin Kimani <josephgodwinke@gmail.com>
 */

import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ScrollToTop from './components/scrollToTop';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<SnackbarProvider>
				<ScrollToTop />
				<App />
			</SnackbarProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
