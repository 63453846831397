/**
 *
 * @author Godwin Kimani <josephgodwinke@gmail.com>
 */

import i18n from 'i18next';
// import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
	en: {
		translation: {
			'Terms and Conditions': 'Terms and Conditions',
			'Open call for AI creators': 'Open call for AI creators',
			Country: 'Country',
			'Enter your full name': 'Enter your full name',
			'Email Address': 'Email Address',
			'Where can we see your work': 'Where can we see your work',
			'I accept the above terms and conditions': 'I accept the above terms and conditions',
			'Please check the captcha': 'Please check the captcha',
			'Look in your mailbox': 'Look in your mailbox',
			'Thanks!': 'Thanks!',
			'You will get an email. Please check your INBOX or SPAM  folder.':
				'You will get an email. Please check your INBOX or SPAM  folder.',
			"Remember to whitelist AI FILMS so you don't miss our answer!":
				"Remember to whitelist AI FILMS so you don't miss our answer!"
		}
	},
	fr: {
		translation: {
			'Terms and Conditions': 'Termes et conditions',
			'Open call for AI creators': "Appel ouvert aux créateurs d'IA",
			Country: 'Pays',
			'Enter your full name': 'Entrez votre nom complet',
			'Email Address': 'Adresse e-mail',
			'Where can we see your work': 'Où pouvons-nous voir votre travail',
			'I accept the above terms and conditions': "J'accepte les termes et conditions ci-dessus",
			'Please check the captcha': 'Veuillez vérifier le captcha',
			'Look in your mailbox': 'Regarde dans ta boite mail',
			'Thanks!': 'Merci!',
			'You will get an email. Please check your INBOX or SPAM  folder.':
				'Vous recevrez un e-mail. Veuillez vérifier votre boîte de réception ou votre dossier SPAM.',
			"Remember to whitelist AI FILMS so you don't miss our answer!":
				"N'oubliez pas de mettre AI FILMS sur liste blanche pour ne pas manquer notre réponse !"
		}
	},
	es: {
		translation: {
			'Terms and Conditions': 'Termes et conditions',
			'Open call for AI creators': 'Convocatoria abierta para creadores de IA',
			Country: 'País',
			'Enter your full name': 'Introduce tu nombre completo',
			'Email Address': 'Dirección de correo electrónico',
			'Where can we see your work': '¿Dónde podemos ver tu trabajo?',
			'I accept the above terms and conditions': 'Acepto los términos y condiciones anteriores',
			'Please check the captcha': 'Por favor revisa el captcha',
			'Look in your mailbox': 'Mira en tu buzón',
			'Thanks!': '¡Gracias!',
			'You will get an email. Please check your INBOX or SPAM  folder.':
				'Recibirás un correo electrónico. Por favor revisa tu carpeta INBOX o SPAM.',
			"Remember to whitelist AI FILMS so you don't miss our answer!":
				'¡Recuerde incluir AI FILMS en la lista blanca para no perderse nuestra respuesta!'
		}
	},
	de: {
		translation: {
			'Terms and Conditions': 'Geschäftsbedingungen',
			'Open call for AI creators': 'Offener Aufruf für KI-Entwickler',
			Country: 'Land',
			'Enter your full name': 'Geben Sie Ihren vollständigen Namen ein',
			'Email Address': 'E-Mail-Adresse',
			'Where can we see your work': 'Wo können wir Ihre Arbeit sehen?',
			'I accept the above terms and conditions':
				'Ich akzeptiere die oben genannten Geschäftsbedingungen',
			'Please check the captcha': 'Bitte überprüfen Sie das Captcha',
			'Look in your mailbox': 'Schauen Sie in Ihren Briefkasten',
			'Thanks!': 'Danke!',
			'You will get an email. Please check your INBOX or SPAM  folder.':
				'Sie erhalten eine E-Mail. Bitte überprüfen Sie Ihren Posteingang oder SPAM-Ordner.',
			"Remember to whitelist AI FILMS so you don't miss our answer!":
				'Denken Sie daran, AI FILMS auf die Whitelist zu setzen, damit Sie unsere Antwort nicht verpassen!'
		}
	},
	pt: {
		translation: {
			'Terms and Conditions': 'Termos e Condições',
			'Open call for AI creators': 'Chamada aberta para criadores de IA',
			Country: 'País',
			'Enter your full name': 'Escreva seu nome completo',
			'Email Address': 'Endereço de email',
			'Where can we see your work': 'Onde podemos ver seu trabalho',
			'I accept the above terms and conditions': 'Aceito os termos e condições acima',
			'Please check the captcha': 'Por favor verifique o captcha',
			'Look in your mailbox': 'Olhe na sua caixa de correio',
			'Thanks!': 'Obrigado!',
			'You will get an email. Please check your INBOX or SPAM  folder.':
				'Você receberá um e-mail. Verifique sua caixa de entrada ou SPAM.',
			"Remember to whitelist AI FILMS so you don't miss our answer!":
				'Lembre-se de colocar AI FILMS na lista de permissões para não perder nossa resposta!'
		}
	},
	hi: {
		translation: {
			'Terms and Conditions': 'नियम और शर्तें',
			'Open call for AI creators': 'एआई रचनाकारों के लिए ओपन कॉल',
			Country: 'देश',
			'Enter your full name': 'अपना पूरा नाम भरें',
			'Email Address': 'मेल पता',
			'Where can we see your work': 'हम आपका काम कहां देख सकते हैं',
			'I accept the above terms and conditions': 'मैं उपरोक्त नियम एवं शर्तें स्वीकार करता हूँ',
			'Please check the captcha': 'कृपया कैप्चा जांचें',
			'Look in your mailbox': 'अपने मेलबॉक्स में देखें',
			'Thanks!': 'धन्यवाद!',
			'You will get an email. Please check your INBOX or SPAM  folder.':
				'आपको एक ईमेल मिलेगा. कृपया अपना इनबॉक्स या स्पैम फ़ोल्डर जांचें।',
			"Remember to whitelist AI FILMS so you don't miss our answer!":
				'एआई फिल्मों को श्वेतसूची में रखना याद रखें ताकि आप हमारा उत्तर न चूकें!'
		}
	}
};

i18n.use(LanguageDetector).init({
	resources,
	lng: 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false
	}
});
