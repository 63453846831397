/**
 *
 * @author Godwin Kimani <josephgodwinke@gmail.com>
 */

import './app.scss';
import './i18n';

import React from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes } from 'react-router-dom';

import CandidatesSubmit from './pages/candidatesSubmit/CandidatesSubmit';
import TermsConditions from './pages/t&cs/TermsConditions';
import Thanks from './pages/thanks/Thanks';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

function App() {
	React.useEffect(() => {
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname,
			title: 'Home Page'
		});
	}, []);

	return (
		<Routes>
			<Route path="/thanks" scrollToTop element={<Thanks />} />
			<Route exact path="/terms-conditions" element={<TermsConditions />} />
			<Route path="/" element={<CandidatesSubmit />} />
		</Routes>
	);
}

export default App;
